import Banner1 from "../assets/images/adBanners/01.png";
import Banner2 from "../assets/images/adBanners/02.png";
import Banner3 from "../assets/images/adBanners/03.png";
import Banner4 from "../assets/images/adBanners/04.png";

export const banners = [
  {
    src: Banner1,
    alt: "Picture 1",
  },
  {
    src: Banner2,
    alt: "Picture 2",
  },
  {
    src: Banner3,
    alt: "Picture 3",
  },
  {
    src: Banner4,
    alt: "Picture 4",
  },
];
